







import Vue from "vue";
import { ServedCode } from "../../model/entity/orderSetting";

export default Vue.component("OrderList", {
  components: {
    HOOrderListTab: () =>
      import("../../components/compositions/HOOrderListTab.vue"),
    HOOrderList: () =>
      import("../../components/components/HOOrderList.vue"),
  },
  data() {
    return {
      servedOrder: ServedCode.SERVED_ORDER,
    };
  }
});
